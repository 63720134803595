import EquansTheme from './equans';
import QuantesTheme from './quantes';

const themes = {
  EQUANS: EquansTheme,
  QUANTES: QuantesTheme,
};

export function getTheme(theme) {
  if (themes[theme]) {
    return themes[theme];
  }
  return themes.EQUANS;
}

/*
export function getTheme() {
  return themes.EQUANS;
}
*/
export default themes;
