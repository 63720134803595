/* eslint-disable no-underscore-dangle */
export const APPLICATION_NAME = 'portal';

export const MULTIPLE_TYPE = 'MULTIPLE';
export const WORK_IN_PROGRESS_TYPE = 'WORKINPROGRESS';
export const WELCOME_TYPE = 'WELCOME';

export const ABSOLUTE = 6;
export const QUICK = 7;
export const RELATIVE = 8;
export const AUTOREFRESH = 9;

export const PICKER_KIBANA_APPLICATIONS = ['kibana'];

// export const LOGO = process.env.LOGO || 'EQUANS';
