/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-const-assign */
import enMessages from './en';
import frMessages from './fr';
import nlMessages from './nl';

const languages = {
  en: {
    messages: enMessages,
    label: 'English',
  },
  fr: {
    messages: frMessages,
    label: 'Français',
  },
  nl: {
    messages: nlMessages,
    label: 'Nederlands',
  },
};

export default languages;
